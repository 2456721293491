import { alphabeticSort } from '@/helpers/alphabeticSort'
import Navigation from '@/interfaces/Navigation'
import Playlist from '@/interfaces/PlayLists'
import anime from 'animejs'
import { LitElement, html, css, unsafeCSS, PropertyValueMap } from 'lit'
import { property, query, state } from 'lit/decorators.js'
import tailwindcssstyles from 'styles/tailwind.css?inline'
import navigation from 'utils/nav-menu.json'

import config from '@/config'

export class MobileMenuTailwind extends LitElement {
  static styles = [css``, unsafeCSS(tailwindcssstyles)]
  @property({ reflect: true })
  getVideosFromPlaylist: ((arg0: string) => void) | undefined
  @property({ reflect: true })
  getVideosFromCategory: ((arg0: string) => void) | undefined

  @property({ type: Array }) nav: Navigation[] = Object.values(navigation)
  @property({ type: Array, attribute: 'playLists', reflect: true })
  playLists: Playlist[] = []
  @property({ type: Number }) savedScrollTop = 0

  @query('.mobile-menu-body') mobileMenuBody: HTMLElement | undefined

  @state() showMobileMenu = false

  protected firstUpdated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(_changedProperties)
    this.mobileMenuBody?.classList.add('hidden')
  }

  // Function to toggle the mobile menu visibility
  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu
  }

  // Update the click event on the hamburger button to toggle the mobile menu
  async handleHamburgerClick() {
    this.toggleMobileMenu()

    if (!this.showMobileMenu) {
      await this.closeMenu()
    }
    if (this.showMobileMenu) {
      await this.openMenu()
    }
  }

  private async closeMenu() {
    document.body.style.overflow = ''
    document.removeEventListener('scroll', this.preventBodyScroll)
    await anime({
      targets: ['.close'],
      height: [24, 0],
      opacity: [1, 0],
      duration: 500,
      easing: 'easeInOutQuad',
    })
    await anime({
      targets: [this.mobileMenuBody],
      opacity: [1, 0],
      duration: 500,
      easing: 'easeInOutQuad',
    }).finished.then(() => {
      setTimeout(() => {
        this.mobileMenuBody?.classList.add('hidden')
      }, 500)
    })
    await anime({
      targets: ['.hamburger'],
      height: [0, 24],
      opacity: [0, 1],
      duration: 500,
      easing: 'easeInOutQuad',
    })
  }
  private async openMenu() {
    this.savedScrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    document.body.style.overflow = 'hidden'
    document.addEventListener('scroll', this.preventBodyScroll)

    this.mobileMenuBody?.classList.remove('hidden')
    await anime({
      targets: [this.mobileMenuBody],
      opacity: [0, 1],
      duration: 500,
      easing: 'easeInOutQuad',
    })
    await anime({
      targets: ['.hamburger'],
      height: [24, 0],
      opacity: [1, 0],
      duration: 500,
      easing: 'easeInOutQuad',
    })
    await anime({
      targets: ['.close'],
      height: [0, 24],
      opacity: [0, 1],
      duration: 500,
      easing: 'easeInOutQuad',
    })
  }

  private preventBodyScroll() {
    document.documentElement.scrollTop = this.savedScrollTop
    document.body.scrollTop = this.savedScrollTop
  }
  render() {
    return html`
      <style>
        .no-scroll {
          overflow: hidden;
          height: 100%;
        }
        .menu-body-list {
          height: calc(100vh - 4rem);
        }
      </style>
      <div
        class="flex items-center justify-end w-full py-2 relative h-8 mobile-menu lg:hidden"
      >
        <!-- Hamburger-Button -->
        <button
          class="hamburger focus:outline-none focus:shadow-outline absolute top-0"
          @click="${this.handleHamburgerClick}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div
          @mouseenter="${(e: { stopPropagation: () => any }) =>
        e.stopPropagation()}"
          class="mobile-menu-body w-screen right-0 fixed bg-white z-20 min-h-screen overflow-y-hidden top-0 p-4"
        >
          <button
            class="close focus:outline-none focus:shadow-outline fixed z-40"
            style="top: 3.65rem; right: 1.1rem;"
            @click="${this.handleHamburgerClick}"
          >
            <div class="px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="24px"
                height="24px"
              >
                <path
                  d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                />
              </svg>
            </div>
          </button>
          <ul class="block z-30 overflow-y-scroll pb-20 menu-body-list">
            ${this.nav.map(
          (navItem) => html`
                <li class="list-none">
                  ${navItem.link === 'playlists' && this.playLists.length
              ? html`
                        <style>
                          .link-color {
                            color: #777;
                            font-weight: bold;
                          }
                          .link-color:hover {
                            color: #333;
                          }
                          .link-color::after {
                            border-color: #777 transparent transparent !important;
                          }
                          .link-color:hover::after {
                            border-color: #333 transparent transparent !important;
                          }
                        </style>

                        <div class="">
                          <div class="dropdown inline-block relative">
                            <button
                              class="py-2 inline-flex items-center"
                              @click="${(e: { preventDefault: () => any }) =>
                  e.preventDefault()}"
                            >
                              <span
                                class="mr-1 link-color text-3xl leading-loose"
                                >${navItem.name}</span
                              >
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                />
                              </svg>
                            </button>
                            <ul class=" text-gray-700 pt-1">
                              ${navItem.link === 'playlists' &&
                alphabeticSort(this.playLists).map(
                  (item: Playlist) => {
                    const playlistId = item?.id.toString()
                    return html`
                                    ${(config.USE_YT_API || item.privacy.label === 'Public' && item.videosLength > 0)
                        ? html`
                                          <li>
                                            <button
                                              @click="${(e: {
                          preventDefault: () => void
                        }) => {
                            e.preventDefault()

                            if (
                              this.getVideosFromPlaylist
                            ) {
                              this.getVideosFromPlaylist(
                                playlistId
                              )
                            }
                            this.showMobileMenu = false
                            this.closeMenu()
                          }}"
                                              class="py-2 px-4 block whitespace-no-wrap cursor-pointer link-color text-3xl leading-loose"
                                            >
                                              ${item.displayName}
                                            </button>
                                          </li>
                                        `
                        : ''}
                                  `
                  }
                )}
                            </ul>
                          </div>
                        </div>
                      `
              : html`
                        <button
                          @click="${(e: { preventDefault: () => void }) => {
                  e.preventDefault()

                  if (this.getVideosFromCategory) {
                    this.getVideosFromCategory(navItem.link)
                  }
                  this.showMobileMenu = false
                  this.closeMenu()
                }}"
                          class="link-color py-2 block whitespace-no-wrap cursor-pointer text-3xl leading-loose"
                        >
                          ${navItem.name}
                        </button>
                      `}
                </li>
              `
        )}
          </ul>
        </div>
      </div>
    `
  }
}

customElements.define('mobile-menu-tailwind', MobileMenuTailwind)
