const padWithZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`)

const germanDate = (isoDate: string): string => {
  const date = new Date(isoDate)

  const day = padWithZero(date.getDate())
  const month = padWithZero(date.getMonth() + 1)
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}

export default germanDate
