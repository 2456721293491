import { LitElement, html, css, CSSResultArray, unsafeCSS } from 'lit'
import footer from 'utils/footer-navigation.json'
import tailwindcssStyles from 'styles/tailwind.css?inline'
import { customElement, property } from 'lit/decorators.js'
import ramLogo from 'images/RA-MICRO.svg'
@customElement('mediathek-footer')
export class Footer extends LitElement {
  static styles: CSSResultArray = [
    css`
      :host {
        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Regular.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Bold.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Italic.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Italic.woff') format('woff');
          font-weight: 400;
          font-style: italic;
        }
        font-family: 'Gudea', sans-serif;
      }
    `,
    unsafeCSS(tailwindcssStyles),
  ]
  @property({ type: String }) framework = 'tailwind'
  @property({ attribute: false }) foundationCss: CSSResultArray | string = ''
  async connectedCallback(): Promise<void> {
    super.connectedCallback()
    if (this.framework === 'foundation') {
      const importedCss = await import(
        '../../node_modules/foundation-sites/dist/css/foundation.min.css?inline'
      )
      this.addFoundationCss(importedCss.default)
    }
  }

  private addFoundationCss(cssText: string) {
    const foundationCss = css`
      ${unsafeCSS(cssText)}
    `
    const updatedStyles = [...Footer.styles, foundationCss]
    if (this.framework === 'foundation') {
      ;(this.constructor as typeof Footer).styles = updatedStyles
    }
  }

  render() {
    return html` <div
      class="container mx-auto mt-16 mb-2 py-2 flex items-center flex-col md:flex-row"
    >
      <a
        href="https://ra-micro.de"
        target="_blank"
      >
        <img
          class="-mt-2 mr-4 w-36 pointer"
          src="${ramLogo}"
          alt="RA-MICRO Software AG"
        />
      </a>
      <p
        class="inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 my-2 md:my-0"
      >
        ${footer.map(
          (link, i) =>
            html`<a
              href="${link.href}"
              target="_blank"
              class="${i === 0 ? 'mr-1' : link.class}"
              >${link.title}</a
            >`
        )}
      </p>
      <p class="inline-flex items-center text-md font-medium text-gray-500">
        <span
          class="mr-1"
          style="font-size: 1.25rem; margin-top: -4px;"
          >©️</span
        >
        RA-MICRO Software AG ${new Date().getFullYear()}
      </p>
    </div>`
  }
}
