import { appContext } from '@/context/appContext'
import AppContext from '@/interfaces/AppContext'
import { urlForName } from '@/router'
import { consume } from '@lit-labs/context'
import { LitElement, html, css, CSSResultArray, unsafeCSS } from 'lit'
import { property, state } from 'lit/decorators.js'
import tailwindcssstyles from 'styles/tailwind.css?inline'

export class BreadCrumbsTailwind extends LitElement {
  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext;

  [key: string]: any
  static styles: CSSResultArray = [css``, unsafeCSS(tailwindcssstyles)]

  @state() breadcrumb?: string = localStorage.getItem('metaTitle') || ''

  @property({ type: Boolean }) useUrlParams = false
  @property({ type: Boolean }) showBreadCrumbs = false
  @property({ type: String }) breadcrumbParam = ''

  handleHomeClick() {
    //console.info('breadcrumbsTailwind.ts (4) - handleHomeClick()')
    this.breadcrumbParam = ''
    this.breadcrumb = ''
    localStorage.setItem('metaTitle', '')
  }
  render() {
    return html`<div class="inline-block relative w-full">
      <nav
        class="sticky top-10  container flex items-center justify-start text-sm font-medium _mt-3 mx-auto lg:px-0 lg:pl-1 py-2 px-2 ${this
        .showBreadCrumbs
        ? 'z-10 bg-white'
        : '-z-1 bg-transparent'}"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 lg:space-x-3 z-30">
          <li class="inline-flex items-center">
            <a
              class="inline-flex items-center text-sm font-medium link-color outline-none focus:outline-none"
              href="${urlForName('home')}"
              @click="${this.handleHomeClick}"
            >
              <svg
                class="w-3 h-3 mr-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"
                />
              </svg>
              Home
            </a>
          </li>
          <li>
            <div class="flex items-center">
              ${(this.breadcrumbParam || this.breadcrumb) &&
        this.breadcrumbParam !== 'Home'
        ? html`
      <svg
                    class="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>`
        :
        html`
      `}
              <button
                class="ml-1 text-sm font-medium outline-none focus:outline-none disabled"
                disabled
              >
                ${this.breadcrumbParam
        ? this.breadcrumbParam !== 'Home'
          ? this.breadcrumbParam
          : ''
        : this.breadcrumb !== 'Home'
          ? this.breadcrumb
          : ''}
              </button>
            </div>
          </li>
        </ol>
      </nav>
    </div>`
  }
}

customElements.define('breadcrumbs-tailwind', BreadCrumbsTailwind)
