import Video from '@/interfaces/Video'
import config from '@/config'

// HTML structure for the video modal
const modal = document.createElement('div')

modal.id = 'videoModal'
modal.innerHTML = `
  <style>

  </style>

    <div id="modal-content" class="modal-content">
    <div id="modal-title" class="modal-title"></div>
      <span class="sizeAndClose">
        <span class="fullsize">
          <span class="full" title="Maximieren">&#x1F5D6;</span>
          <span class="resize" title="Wiederherstellen">&#x1F5D7;</span>
        </span>
        <span class="close">&#xD7;</span>
      </span>
      <div id="videoPlayer"></div>
      <div id="modal-description" class="modal-description"></div>
    </div>
  `

document.body.appendChild(modal)

// JavaScript code for the video modal
const modalContentElement = document.getElementById('modal-content')
const modalElement = document.getElementById('videoModal')
const videoPlayerElement = document.getElementById('videoPlayer')
const modalTitleElement = document.getElementById('modal-title')
const modalDescriptionElement = document.getElementById('modal-description')

// Function to open the video modal
export function openVideoModal(
  videoUrl: Video['embedPath'],
  titel: Video['name'],
  description: Video['description']
) {
  if (
    modalElement &&
    videoPlayerElement &&
    modalTitleElement &&
    modalDescriptionElement
  ) {
    // Create video player HTML
    const iframeHtml =
      config.USE_YT_API ?
        `<iframe 
          width="560"
          height="315"
          src="${videoUrl}?fs=1&iv_load_policy=3&rel=0&autoplay=1"
          title="${titel}"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen></iframe>` :
        `<iframe src="${videoUrl}?p2p=0&peertubeLink=0" frameborder="0" allowfullscreen></iframe>`

    videoPlayerElement.innerHTML = iframeHtml
    // add title and description
    modalTitleElement.textContent = titel
    modalDescriptionElement.textContent = description
    // Show modal
    modalElement.style.display = 'block'
  }
}

// Function to close the video modal
export function closeVideoModal() {
  if (modalElement && videoPlayerElement) {
    // Reset video player
    videoPlayerElement.innerHTML = ''

    // Hide modal
    modalElement.style.display = 'none'
  }
}

// Function to fullsize the video modal
export function fullsizeVideoModal() {
  if (modalContentElement) {
    modalContentElement.classList.toggle('video-player-max');
  }
}

// Event listeners for the close button and clicking outside the modal
const closeButton = modalElement?.querySelector('.close')
if (closeButton) {
  closeButton.addEventListener('click', closeVideoModal)
}
window.addEventListener('click', (event) => {
  if (modalElement && event.target === modalElement) {
    closeVideoModal()
  }
})

// Event listeners for the fullsize button
const fullsizeButton = modalElement?.querySelector('.fullsize')
if (fullsizeButton) {
  fullsizeButton.addEventListener('click', fullsizeVideoModal)
}
