import { html, LitElement, css } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

@customElement('scroll-to-top')
export class ScrollToTop extends LitElement {
  @property()
  framework: string = ''

  @state()
  private showScrollButton: boolean = false

  static styles = css`
    .scroll-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      padding: 10px 15px;
      font-size: 20px;
      cursor: pointer;
      transition: background 0.5s;
      border: none;
      border-radius: 50%;
    }
    .scroll-button.tailwind {
      background: #2ba6cb;
      color: white;
      border-radius: 50%;
    }
    .scroll-button.tailwind:hover {
      background: #165366;
    }
    .scroll-button.bootstrap {
      background: #2ba6cb;
      color: white;
      border-radius: 50%;
    }
    .scroll-button.bootstrap:hover {
      background: #165366;
    }
    .scroll-button.foundation {
      background: #2ba6cb;
      color: white;
      border-radius: 50%;
    }
    .scroll-button.foundation:hover {
      background: #165366;
    }
    .scroll-button.custom {
      background: #1976d2;
      color: white;
      border-radius: 50%;
    }
    .scroll-button.custom:hover {
      background: #165366;
    }
  `
  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('scroll', this.handleScroll)
  }

  disconnectedCallback() {
    window.removeEventListener('scroll', this.handleScroll)
    super.disconnectedCallback()
  }

  private handleScroll = () => {
    if (window.pageYOffset > 100) {
      this.showScrollButton = true
    } else {
      this.showScrollButton = false
    }
  }

  private scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  render() {
    return this.showScrollButton
      ? html`<button
          @click="${this.scrollToTop}"
          class="scroll-button ${this.framework}"
        >
          ↑
        </button>`
      : html``
  }
}
