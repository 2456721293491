import {
  LitElement,
  html,
  css,
  TemplateResult,
  unsafeCSS,
  CSSResultArray,
} from 'lit'
import { customElement, property } from 'lit/decorators.js'
import Video from '@/interfaces/Video'
import { BASE_URL } from 'services/api.service'
import { openVideoModal } from 'components/modal'
import { consume } from '@lit-labs/context'
import { appContext } from '@/context/appContext'
import AppContext from '@/interfaces/AppContext'
import { router } from '@/router'
import foundationCss from '../../node_modules/foundation-sites/dist/css/foundation.min.css?inline'
@customElement('foundation-template')
export class FoundationTemplate extends LitElement {
  static styles: CSSResultArray = [
    css`
      :host {
        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Regular.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Bold.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Italic.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Italic.woff') format('woff');
          font-weight: 400;
          font-style: italic;
        }
        font-family: 'Gudea', sans-serif;
      }
      .videobox {
        padding: 0.5rem;
        height: auto !important;
      }
      .videomodal {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        padding-top: 1rem !important;
        background-color: #f6f6f6;
        border: 1px solid #e6e6e6 !important;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        height: 100% !important;
      }
      .text-klein,
      .text-klein p {
        font-size: 100%;
        line-height: inherit;
      }
      .mt-1 {
        margin-top: 1rem !important;
      }
      .reveal-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1005;
        display: none;
        background-color: rgba(10, 10, 10, 0.45);
        overflow-y: auto;
      }
      .video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
      }
      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
      .badge {
        position: absolute;
        right: 1rem;
        height: 3rem;
        width: 3rem;
        line-height: 2.5rem;
        font-size: 1rem !important;
        font-weight: 700;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }
      .grid-x {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
      .grid-container {
        padding: 0 !important;
      }
    `,
    unsafeCSS(foundationCss),
  ]

  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext

  @property({ type: Array }) videos: Video[] = []
  @property({ type: Boolean }) isPlayList = false

  descriptionShortener(text: string, num: number = 100): string {
    return text?.length > num ? text.substring(0, num) + ' ...' : text
  }

  getLatestVideo(): number | string {
    const latestVideo: Video[] = this.videos?.sort((a: Video, b: Video) => {
      const dateA: Date = new Date(a.publishedAt)
      const dateB: Date = new Date(b.publishedAt)
      return dateB.getTime() - dateA.getTime()
    })

    return latestVideo[0].id.toString()
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback()
    const query = router.location.route?.name
    if (query === 'playlist' || query === 'category') {
      this.isPlayList = true
    }
  }

  protected render(): TemplateResult {
    return html`
      ${html` <div class="container mt-1">
        <div class="grid-x">
          ${this.videos
            ?.sort(
              (a, b) =>
                new Date(
                  this.isPlayList
                    ? b.video.originallyPublishedAt
                    : b.originallyPublishedAt
                ).getTime() -
                new Date(
                  this.isPlayList
                    ? a.video.originallyPublishedAt
                    : a.originallyPublishedAt
                ).getTime()
            )
            .map((video: Video, i: number) =>
              video && video !== null && video.id !== null
                ? html`
                    <div class="cell medium-6 large-4 videobox">
                      <div
                        class="videomodal"
                        data-open="${'videoModal-' + video.id}"
                        aria-controls="${'videoModal-' + video.id}"
                        aria-haspopup="true"
                        tabindex="0"
                        @click="${() =>
                          openVideoModal(
                            this.isPlayList
                              ? BASE_URL + video.video.embedPath
                              : BASE_URL + video.embedPath,
                            this.isPlayList ? video.video.name : video.name,
                            this.isPlayList
                              ? video.video.description
                              : video.description
                          )}"
                        data-scroll="${i}"
                        data-link="${this.isPlayList
                          ? video.video.embedPath
                          : video.embedPath}"
                      >
                        ${this.context.showBadgeNewVideo &&
                        this.getLatestVideo() === video.id.toString()
                          ? html`<div class="badge">Neu</div>`
                          : ''}
                        <figure
                          role="group"
                          style="margin: .5rem"
                        >
                          <a>
                            <img
                              src="${this.isPlayList
                                ? BASE_URL + video.video.thumbnailPath
                                : BASE_URL + video.thumbnailPath}"
                              alt="${this.isPlayList
                                ? video.video.name
                                : video.name}"
                              title="${this.isPlayList
                                ? video.video.description
                                : video.description}"
                              loading="lazy"
                              style="width: 500px; display: block; margin: auto; background-color: white;"
                            />
                          </a>
                          <figcaption
                            style="max-width: 500px; margin: 0.5rem auto 0px;"
                          >
                            <p class="text-klein">
                              ${this.isPlayList ? video.video.name : video.name}
                            </p>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  `
                : html``
            )}
        </div>
      </div>`}
    `
  }
}
