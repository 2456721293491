const durationConverter = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = padNumber(minutes)
  const formattedSeconds = padNumber(remainingSeconds)

  return `${formattedMinutes}:${formattedSeconds}`
}

function padNumber(number: number): string {
  return number.toString().padStart(2, '0')
}

export default durationConverter
