import Category from 'interfaces/Category'
import config from '@/config'
import playlistIdMapping from '@/playlist-id-mapping.json' assert { type: 'json' };

export enum DataLoadSource {
  API = 'api',
  IndexedDB = 'indexeddb',
}

export const BASE_URL = 'https://mediathek.ra-micro.de'
export const YT_BASE_URL = 'https://youtube.googleapis.com/youtube/v3'
export const YT_CHANNEL_ID = 'UCKSMVP1Na6A0KAKZsP7B0yA'
//export const YT_API_KEY = 'AIzaSyBI0plk2CdI6uC0lSpi4hKUrsqfr2scLzw' // Projekt-Id: rmo-mediathek-dq
export const YT_API_KEY = 'AIzaSyDw9u2r3jbUCjaXSgAXYsbg-I62of63YyY' // Projekt-Id: youtube-api-ra-micro-mediathek

const MAX_ITEMS_PER_PAGE = 15

export class ApiService {

  async fetchAndHandleErrors(url: string, errorMessage?: string): Promise<any> {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(
          errorMessage || `HTTP error! status: ${response.status}`
        )
      }
      return await response.json()
    } catch (error) {
      console.error(
        `A problem occurred while fetching the data from ${url}`,
        error
      )
      throw error
    }
  }

  // getFeed wird aktuell nicht wohl nicht verwendet 
  // und wird daher zunächst nicht auf YT API umgestellt
  async getFeed(
    sort = '-createdAt',
    errorMessage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number }> {
    const cacheKey = `feed_${sort}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)
    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    const url = `${BASE_URL}/feeds/videos.json?sort=${sort}`
    const data = await this.fetchAndHandleErrors(url, errorMessage)

    const { items } = data
    const total = data.total || items.length

    this.cacheData(cacheKey, { data: items, total })

    return { data: items, source: DataLoadSource.API, total }
  }

  /**
   * Holt die Liste der Playlists
   * @param errorMessage 
   * @returns 
   */
  async getPlaylists(
    errorMessage?: string
  ): Promise<{ data: any[]; source: DataLoadSource; total: number }> {

    const cacheKey = `playlists${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)

    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    let start = 0
    let playlists: any[] | PromiseLike<any[]> = []
    let hasMore = true
    let total = 0

    let nextPageToken = ''

    while (hasMore) {

      let data: any[] = []
      let fetchedTotal: number = 0

      if (config.USE_YT_API) {

        const endpoint = `/playlists`
        const params = `part=snippet%2CcontentDetails&channelId=${YT_CHANNEL_ID}&maxResults=${MAX_ITEMS_PER_PAGE}&key=${YT_API_KEY}&pageToken=${nextPageToken}`
        const url = `${YT_BASE_URL}${endpoint}?${params}`

        const response = await this.fetchAndHandleErrors(
          url,
          errorMessage
        )
        data = response.items.filter((item: any) => item.contentDetails.itemCount !== 0);
        fetchedTotal = response.pageInfo.totalResults
        nextPageToken = response.nextPageToken ?? ''
      }
      else {

        const url = `${BASE_URL}/api/v1/video-channels/kanzleisoftware/video-playlists?start=${start}&count=${MAX_ITEMS_PER_PAGE}`

        const response = await this.fetchAndHandleErrors(
          url,
          errorMessage
        )
        data = response.data
        fetchedTotal = response.total
      }
      playlists = [...playlists, ...data]
      total = fetchedTotal

      hasMore = total > start + MAX_ITEMS_PER_PAGE
      start += MAX_ITEMS_PER_PAGE
    }

    this.cacheData(cacheKey, { data: playlists, total })

    return { data: playlists, source: DataLoadSource.API, total }
  }

  /**
   * Holt alle Videos
   * @param start 
   * @param count Anzahl der Videos, die (pro Seite) geholt werden sollen (für YT API max. 50)
   * @param errorMessage 
   * @returns 
   */
  async getAllVideos(
    start = 0,
    count = 100,
    errorMessage?: string,
    tokenNextPage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number; nextPageToken: string }> {

    const cacheKey = `videos_${start}_${count}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)

    let newNextPageToken = ''

    //console.info(`getAllVideos(${start}, ${count}, errorMessage, '${tokenNextPage}') - cacheKey: ${cacheKey}, cachedData: ${cachedData === null ? 'null' : 'not null'}`)

    if (cachedData) {

      //console.info(`getAllVideos(${start}, ${count}, errorMessage, '${tokenNextPage}') - cacheKey: ${cacheKey}, cachedData.total: ${cachedData.total}, cachedData.nextPageToken: ${cachedData.nextPageToken}`)

      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
        nextPageToken: cachedData.nextPageToken
      }
    }

    let total = 0

    let data: any[] = []
    let fetchedTotal: number = 0

    if (config.USE_YT_API) {

      const endpoint = `/search`
      const params = `part=snippet&channelId=${YT_CHANNEL_ID}&maxResults=${count < 50 ? count : 50}&type=video&key=${YT_API_KEY}&pageToken=${tokenNextPage}&order=date`
      const url = `${YT_BASE_URL}${endpoint}?${params}`

      const response = await this.fetchAndHandleErrors(
        url,
        errorMessage
      )
      data = response.items

      data = data.map(item => {
        return {
          ...item,
          idYT: item.id,  // Neues Feld idYT
          id: undefined   // Entfernen der alten id
        };
      });

      fetchedTotal = response.pageInfo.totalResults

      newNextPageToken = response.nextPageToken ?? ''
    }
    else {

      const endpoint = `/api/v1/videos`
      const params = `start=${start}&count=${count}`
      const url = `${BASE_URL}${endpoint}?${params}`

      const response = await this.fetchAndHandleErrors(
        url,
        errorMessage
      )
      data = response.data
      fetchedTotal = response.total
    }
    total = fetchedTotal

    this.cacheData(cacheKey, { data, total, nextPageToken: newNextPageToken })
    return { data, source: DataLoadSource.API, total, nextPageToken: newNextPageToken }
  }

  // getCategories wird aktuell nicht wohl nicht verwendet
  // und wird daher zunächst nicht auf YT API umgestellt
  async getCategories(
    errorMessage?: string
  ): Promise<{ data: any; source: DataLoadSource; total?: number }> {
    const cacheKey = `categories${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)
    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    const url = `${BASE_URL}/api/v1/videos/categories`
    const data = await this.fetchAndHandleErrors(url, errorMessage)

    this.cacheData(cacheKey, { data })

    return { data, source: DataLoadSource.API }
  }

  async searchByTag(
    tag: string,
    errorMessage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number }> {
    const cacheKey = `search_tag_${tag}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)
    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    const url = `${BASE_URL}/api/v1/search/videos?tagsOneOf=${tag}&start=0&count=100`
    const { data, total } = await this.fetchAndHandleErrors(url, errorMessage)

    this.cacheData(cacheKey, { data, total })

    return { data, source: DataLoadSource.API, total }
  }

  // searchByCategory wird aktuell nicht wohl nicht verwendet
  // und wird daher zunächst nicht auf YT API umgestellt
  async searchByCategory(
    category: number,
    start = 0,
    count = 100,
    errorMessage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number }> {
    const cacheKey = `search_category_${category}_${start}_${count}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)
    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    const url = `${BASE_URL}/api/v1/search/videos?categoryOneOf=${category}&start=${start}&count=${count}`
    const { data, total } = await this.fetchAndHandleErrors(url, errorMessage)

    this.cacheData(cacheKey, { data, total })

    return { data, source: DataLoadSource.API, total }
  }

  // getVideosInCategories wird aktuell nicht wohl nicht verwendet
  // und wird daher zunächst nicht auf YT API umgestellt
  async getVideosInCategories(
    categories: Category[],
    start = 0,
    count = 100,
    errorMessage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number }> {
    const categoryParams = Object.entries(categories)
      .map(([key]) => `categoryOneOf=${key}`)
      .join('&')

    const cacheKey = `videos_categories_${categoryParams}_${start}_${count}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)
    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
      }
    }

    const url = `${BASE_URL}/api/v1/videos?${categoryParams}&start=${start}&count=${count}`
    const { data, total } = await this.fetchAndHandleErrors(url, errorMessage)

    this.cacheData(cacheKey, { data, total })

    return { data, source: DataLoadSource.API, total }
  }

  async getVideosInPlayLists(
    id: number | string,
    start = 0,
    count = 100,
    errorMessage?: string,
    tokenNextPage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number; nextPageToken: string }> {

    const cacheKey = `videos_playlist_${id}_${start}_${count}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)

    let newNextPageToken = ''

    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
        nextPageToken: cachedData.nextPageToken
      }
    }

    let total = 0

    let data: any[] = []
    let fetchedTotal: number = 0

    if (config.USE_YT_API) {

      let mappedId = '0'
      if (typeof id === 'number' || !isNaN(parseInt(id))) {
        const mappedPlaylist = getMappedId(parseInt(id.toString()));
        mappedId = mappedPlaylist ? mappedPlaylist.id : id.toString();
      }
      else {
        mappedId = id;
      }

      const endpoint = `/playlistItems`
      const params = `part=snippet,contentDetails,id,status&playlistId=${mappedId}&maxResults=${count < 50 ? count : 50}&key=${YT_API_KEY}&pageToken=${tokenNextPage ?? ''}`
      const url = `${YT_BASE_URL}${endpoint}?${params}`

      const response = await this.fetchAndHandleErrors(
        url,
        errorMessage
      )
      data = response.items.filter((item: any) => item.status.privacyStatus !== 'private');

      data = data.map(item => {
        return {
          ...item,
          idYT: item.id,  // Neues Feld idYT
          id: undefined   // Entfernen der alten id
        };
      });

      fetchedTotal = response.pageInfo.totalResults
      fetchedTotal = data.length

      newNextPageToken = response.nextPageToken ?? ''
    }
    else {

      const endpoint = `/api/v1/video-playlists/${id}/videos`
      const params = `start=${start}&count=${count}`
      const url = `${BASE_URL}${endpoint}?${params}`

      const response = await this.fetchAndHandleErrors(
        url,
        errorMessage
      )
      data = response.data
      fetchedTotal = response.total
    }
    total = fetchedTotal

    this.cacheData(cacheKey, { data, total, nextPageToken: newNextPageToken })
    return { data, source: DataLoadSource.API, total, nextPageToken: newNextPageToken }
  }

  // Keyword search
  async searchByKeyword(
    keyword: string,
    start = 0,
    count = 100,
    errorMessage?: string,
    tokenNextPage?: string
  ): Promise<{ data: any; source: DataLoadSource; total: number; nextPageToken: string }> {

    if (keyword.length < 3) {
      return { data: [], source: DataLoadSource.API, total: 0, nextPageToken: '' }
    }

    const cacheKey = `search_keyword_${keyword}_${start}_${count}${config.USE_YT_API ? '_YT' : ''}`
    const cachedData = await this.getCachedData(cacheKey)

    let newNextPageToken = ''

    if (cachedData) {
      return {
        data: cachedData.data,
        source: DataLoadSource.IndexedDB,
        total: cachedData.total,
        nextPageToken: cachedData.nextPageToken
      }
    }

    let total = 0

    let data: any[] = []
    let fetchedTotal: number = 0

    if (config.USE_YT_API) {

      const endpoint = `/search`
      const params = `part=snippet&channelId=${YT_CHANNEL_ID}&q=${keyword}&maxResults=${count < 50 ? count : 50}&type=video&key=${YT_API_KEY}&pageToken=${tokenNextPage ?? ''}`
      const url = `${YT_BASE_URL}${endpoint}?${params}`

      const response = await this.fetchAndHandleErrors(
        url,
        errorMessage
      )
      data = response.items//.filter((item: any) => item.status.privacyStatus !== 'private');

      data = data.map(item => {
        return {
          ...item,
          idYT: item.id,  // Neues Feld idYT
          id: undefined   // Entfernen der alten id
        };
      });

      fetchedTotal = response.pageInfo.totalResults
      fetchedTotal = data.length

      newNextPageToken = response.nextPageToken ?? ''
    }
    else {
      const url = `${BASE_URL}/api/v1/search/videos?search=${keyword}&start=${start}&count=${count}`

      // Die folgende Zeile wurde durch die nächste Zeile ersetzt.
      //const { data, total } = await this.fetchAndHandleErrors(url, errorMessage)
      const response = await this.fetchAndHandleErrors(url, errorMessage)

      data = response.data
      fetchedTotal = response.total
    }
    total = fetchedTotal

    this.cacheData(cacheKey, { data, total, nextPageToken: newNextPageToken })
    return { data, source: DataLoadSource.API, total, nextPageToken: newNextPageToken }
  }

  // Helper functions for caching

  async getCachedData(cacheKey: string): Promise<any | null> {
    const db = await this.openDB()
    const transaction = db.transaction('cache', 'readonly')
    const store = transaction.objectStore('cache')

    const request = store.get(cacheKey)

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        const cachedData = request.result
        if (cachedData) {
          const expirationDate = new Date(cachedData.expirationDate)
          const currentDate = new Date()

          if (currentDate <= expirationDate) {
            resolve(cachedData.data)
          } else {
            resolve(null) // Cache abgelaufen, Daten erneuern
          }
        } else {
          resolve(null)
        }
      }

      request.onerror = () => {
        reject(new Error('Fehler beim Abrufen der gecachten Daten'))
      }
    })
  }

  async cacheData(cacheKey: string, data: any): Promise<void> {
    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + 24) // 24 Stunden Gültigkeit

    //const filteredData = data.filter((item: any) => item.status.privacyStatus !== 'private');

    const db = await this.openDB()
    const transaction = db.transaction('cache', 'readwrite')
    const store = transaction.objectStore('cache')

    store.put({
      id: cacheKey,
      data: data,
      expirationDate: expirationDate.toISOString(),
    })
  }

  async openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ramMediathek', 1)

      request.onerror = () => {
        reject(new Error('Fehler beim Öffnen der Datenbank'))
      }

      request.onsuccess = (event) => {
        const db = (event as any).target.result as IDBDatabase
        resolve(db)
      }

      request.onupgradeneeded = (event) => {
        const db = (event as any).target.result as IDBDatabase
        db.createObjectStore('cache', { keyPath: 'id' })
      }
    })
  }
}

// Export an instance of the ApiService
export const apiService = new ApiService()

interface PlaylistMapping {
  id: string;
  name: string;
}

interface PlaylistIdMapping {
  [key: string]: PlaylistMapping;
}

const playlistIdMappingTyped: PlaylistIdMapping = playlistIdMapping;

function getMappedId(id: number): PlaylistMapping | undefined {
  return playlistIdMappingTyped[id.toString()];
}
