import { themeContext } from '@/context/themeContext'
import ThemeContext from '@/interfaces/ThemeContext'
import { consume } from '@lit-labs/context'
import { CSSResultArray, css, unsafeCSS } from 'lit'
import { LitElement, html, property } from 'lit-element'
import tailwindcssstyles from 'styles/tailwind.css?inline'

export class ThemeSwitcher extends LitElement {
  static styles: CSSResultArray = [css``, unsafeCSS(tailwindcssstyles)]

  @consume({ context: themeContext, subscribe: true })
  context!: ThemeContext

  @property({ type: Boolean })
  darkMode: boolean

  constructor() {
    super()
    this.darkMode = false
  }

  updated(changedProperties: Map<string, boolean>) {
    if (changedProperties.has('darkMode')) {
      if (this.darkMode) {
        document.body.classList.add('dark')
        localStorage.setItem('theme', 'dark')
      } else {
        document.body.classList.remove('dark')
        localStorage.setItem('theme', 'light')
      }
    }
  }

  connectedCallback() {
    super.connectedCallback()
    const savedDarkMode = localStorage.getItem('theme')
    this.darkMode = savedDarkMode === 'dark' ? true : false
  }

  render() {
    return html`
      <button
        class="inline-block focus:outline-none"
        @click="${this._toggleDarkMode}"
        title="${this.darkMode
          ? 'Switch to light mode'
          : 'Switch to dark mode'}"
      >
        <span class="text-2xl"> ${this.darkMode ? '🌙' : '☀️'} </span>
      </button>
    `
  }

  private _toggleDarkMode() {
    this.darkMode = !this.darkMode
    this.context.setTheme()
  }
}

customElements.define('theme-switcher', ThemeSwitcher)
