import { openVideoModal } from 'components/modal'
import { Ref, createRef, ref } from 'lit/directives/ref.js'
import SearchVideos from '@/interfaces/SearvhVideos'
import Video from '@/interfaces/Video'
import {
  LitElement,
  html,
  css,
  PropertyValues,
  unsafeCSS,
  CSSResultArray,
  TemplateResult,
} from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { BASE_URL, apiService } from 'services/api.service'
import tailwindcssstyles from 'styles/tailwind.css?inline'
import config from '@/config'

@customElement('video-search')
export class VideoSearch extends LitElement {
  @state() private keyword = ''
  @state() private results: Video[] = []
  @state() private selectedIndex = -1
  @property({ attribute: false })
  handleBreadCrumbs!: (chip: string) => void

  searchInputRef: Ref<HTMLInputElement> = createRef()
  searchList: Ref<HTMLUListElement> = createRef()

  static styles: CSSResultArray = [
    css`
      :host {
        display: block;
        padding-top: 16px;
        color: var(--video-search-text-color, black);
      }
      @media (min-width: 768px) {
        :host {
          /* padding: 16px; */
        }
      }
      .input-fix {
        min-width: 100%;
      }
      @media (min-width: 768px) {
        .input-fix {
          min-width: 18rem;
        }
      }
    `,
    unsafeCSS(tailwindcssstyles),
  ]

  updateKeyword(e: { target: { value: string } }) {
    this.keyword = e.target.value
    this.selectedIndex = -1
  }

  async updated(changedProperties: PropertyValues) {
    if (changedProperties.has('keyword') && this.keyword.length) {
      if (this.keyword.length) {
        this.handleBreadCrumbs('hide')
      } else {
        this.handleBreadCrumbs('show')
      }
      const response: SearchVideos = await apiService.searchByKeyword(
        this.keyword
      )
      this.results = response.data
    }
  }
  async firstUpdated() {
    await this.updateComplete

    if (typeof this.handleBreadCrumbs === 'function') {
      this.handleBreadCrumbs('show')
    }
    if (this.searchList.value) {
      this.searchList.value.addEventListener('mouseenter', (e) => {
        e.stopPropagation()
        e.preventDefault()
      })
    }
  }

  private handleClearInput(e: Event) {
    e.preventDefault()
    this.keyword = ''
    this.searchInputRef.value!.focus()
  }

  private handleKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        if (this.selectedIndex > 0) {
          this.selectedIndex--
        }
        break
      case 'ArrowDown':
        e.preventDefault()
        if (this.selectedIndex < this.results.length - 1) {
          this.selectedIndex++
        }
        break
      case 'Enter':
        e.preventDefault()
        if (
          this.selectedIndex >= 0 &&
          this.selectedIndex < this.results.length
        ) {
          const video = this.results[this.selectedIndex]
          openVideoModal(video.url, video.name, video.description)
          this.handleClearInput(e)
        }
        break
    }
  }

  protected render(): TemplateResult {
    return html` <div class="w-full lg:mx-auto">
        <div
          class="relative flex items-center justify-between w-full h-12 rounded-lg border bg-white overflow-hidden"
        >
          <div class="grid place-items-center h-full w-12 text-gray-300 px-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <input
            class="focus:outline-none p-1 text-md flex-1 input-fix"
            type="text"
            .value="${this.keyword}"
            @input="${this.updateKeyword}"
            @keydown="${this.handleKeyDown}"
            placeholder="Suchen"
            ${ref(this.searchInputRef)}
          />
          <div
            style="height: 100%; width: 30px"
            class="flex items-center justify-center"
          >
            ${this.keyword.length
        ? html`<button
                  class="focus:outline-none text-gray-400 mx-1 hover:text-gray-700"
                  @click="${this.handleClearInput}"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>`
        : html``}
          </div>
        </div>
      </div>
      <div
        class="absolute z-40 bg-white transition-all ${this.results.length &&
        this.keyword.length
        ? 'block drop-shadow-lg'
        : 'hidden'}"
        ${ref(this.searchList)}
        style=""
      >
        <h3 class="mt-2 text-sm text-gray-400">Ergebnis:</h3>
        <ul
          class="bg-white border border-gray-100 w-full mt-1 overflow-x-hidden overflow-y-scroll max-h-screen"
        >
          ${this.results.map(
          (video, i) => html`<li
              class="flex items-center min-w-fit justify-start px-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900 text-sm text-gray-700 ${i ===
              this.selectedIndex
              ? 'bg-yellow-50 text-gray-900'
              : ''}"
            >
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                style="enable-background:new 0 0 24 24;"
                xml:space="preserve"
                class="w-4 h-4 mr-2"
              >
                <g>
                  <path
                    d="M9.6,11.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l4.9-3.1c0.1-0.1,0.2-0.2,0.2-0.4S15,7.5,14.9,7.4L10,4.3
		c-0.1-0.1-0.3-0.1-0.5,0C9.4,4.4,9.3,4.5,9.3,4.7v6.2C9.3,11.1,9.4,11.2,9.6,11.3z M10.2,5.5l3.6,2.3l-3.6,2.3V5.5z"
                  />
                  <path
                    d="M0-0.2v24.4h24V-0.2H0z M23.1,0.7v14.7H0.9V0.7H23.1z M0.9,23.3v-7.1h22.2v7.1H0.9z"
                  />
                  <path
                    d="M20.9,19.3H10.2v-0.9c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.9H7.6c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h1.8
		v0.9c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.9h10.7c0.2,0,0.4-0.2,0.4-0.4C21.3,19.5,21.1,19.3,20.9,19.3z"
                  />
                  <path
                    d="M3.1,18c-0.2,0-0.4,0.2-0.4,0.4v2.7c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-2.7C3.6,18.2,3.4,18,3.1,18z"
                  />
                  <path
                    d="M4.9,18c-0.2,0-0.4,0.2-0.4,0.4v2.7c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-2.7C5.3,18.2,5.1,18,4.9,18z"
                  />
                </g>
              </svg>

              <button
                class="focus:outline-none w-full text-left"
                @click="${(e: Event) => {
              if (config.USE_YT_API) {
                openVideoModal(
                  config.YT_EMBED_BASEURL + video.idYT.videoId,
                  video.snippet.title,
                  video.snippet.description
                )
              }
              else {
                openVideoModal(
                  BASE_URL + video.embedPath,
                  video.name,
                  video.description
                )
              }
              this.handleClearInput(e)
            }}"
              >
                ${config.USE_YT_API ? video.snippet.title : video.name}
              </button>
            </li> `
        )}
        </ul>
      </div>`
  }
}
