import {
  LitElement,
  html,
  css,
  unsafeCSS,
  CSSResultArray,
  TemplateResult,
} from 'lit'
import navigation from 'utils/nav-menu.json'
import Navigation from 'interfaces/Navigation'
import { property, customElement } from 'lit/decorators.js'
import Categories from 'interfaces/Categories'
import tailwindcssstyles from 'styles/tailwind.css?inline'
import Playlist from '@/interfaces/PlayLists'
import PlaylistYT from '@/interfaces/PlayListsYT'
import { alphabeticSort } from '@/helpers/alphabeticSort'
import { Ref, createRef, ref } from 'lit/directives/ref.js'
import { router, urlForName } from '@/router'
import { apiService } from '@/services/api.service'
import errorMessages from 'utils/error-messages-de.json'
import { appContext } from '@/context/appContext'
import AppContext from 'interfaces/AppContext'
import { consume } from '@lit-labs/context'

import 'components/search'
import 'components/breadcrumbsTailwind'
import '@/components/mobileMenuTailwind'

import config from '@/config'
import { forwardMapping, reverseMapping } from '@/playlist-id-mapping'

// Define the Breakpoint type
type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'largeDesktop' | 'extraLargeDesktop' | 'xxlDesktop'

// Define breakpoints in pixels
const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1200,
  extraLargeDesktop: 1400,
  xxlDesktop: 1600,
}

// Define the custom element "header-tailwind" representing the header with navigation, breadcrumbs, and responsive layout.
@customElement('header-tailwind')
export class HeaderTailwind extends LitElement {
  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext;

  // Define the properties of the custom element using @property decorator.
  [key: string]: any
  static styles: CSSResultArray = [
    css`
      :host {
        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Regular.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Bold.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Italic.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Italic.woff') format('woff');
          font-weight: 400;
          font-style: italic;
        }
        font-family: 'Gudea', sans-serif;
      }
      :host([noScroll]) {
        overflow: hidden;
      }
      .dropdown:hover .dropdown-menu {
        display: block;
      }
      .link-color {
        color: #777;
      }
      .link-color:hover {
        color: #333;
      }
      .link-color::after {
        border-color: #777 transparent transparent !important;
      }
      .link-color:hover::after {
        border-color: #333 transparent transparent !important;
      }
      .disabled {
        color: #777;
        cursor: initial;
      }
      .logo-fix {
        min-width: 8rem;
      }
    `,
    unsafeCSS(tailwindcssstyles),
  ]

  @property({ type: Array, attribute: false }) nav: Navigation[] =
    Object.values(navigation)
  @property({ type: Array, attribute: false }) categories: Categories[] = []
  @property({ type: String, attribute: 'title', reflect: true })
  title = ''
  @property({ type: Array, attribute: false }) playlists: Playlist[] = []
  @property({ type: Array, attribute: false }) playlistsYT: PlaylistYT[] = []
  @property({ type: Boolean, attribute: 'search' })
  @property({ type: Boolean, attribute: false })
  showMobileMenu = false
  @property({ type: Boolean, attribute: false }) useUrlParams = false
  @property({ type: Boolean, attribute: false }) showBreadCrumbs = false
  @property({ type: String, attribute: 'logo', reflect: true }) logo = ''
  @property({ type: String, attribute: false, reflect: true }) breadcrumbParam =
    ''
  @property({ type: Array, attribute: false }) currentPlayList: Playlist[] = []
  @property({ type: Array, attribute: false }) currentPlayListYT: PlaylistYT[] = []

  dropdownBodyRef: Ref<HTMLInputElement> = createRef()
  @property({ type: String, attribute: false }) currentBreakpoint: Breakpoint =
    'largeDesktop'
  resizeListener = () => this.detectBreakpoint()
  @property({ type: Number, attribute: 'playlistId', reflect: true })
  playlistId?: number = +localStorage.getItem('playlistId')! || 0

  // Lifecycle callback when the component is added to the DOM.
  async connectedCallback(): Promise<void> {
    //console.info('tailwindNavigationTemplate.ts (1) - connectedCallback()')

    localStorage.setItem('metaTitle', '')

    super.connectedCallback()
    this.showBreadCrumbs = true
    document.addEventListener('scroll', this.fixForDropdownMenuOnScroll)
    window.addEventListener('popstate', this.handlePopState.bind(this))
    this.detectBreakpoint()
    window.addEventListener('resize', this.resizeListener)
    this.useUrlParams = this.context?.useUrlParams
    const playLists = await apiService.getPlaylists(errorMessages.playlists)
    this.playlists = playLists.data
    this.playlistsYT = playLists.data
    if (this.context?.breadcrumbs) this.breadcrumbs = this.context?.breadcrumbs

    // eingefügt/DQ/07.05.2024
    const path = window.location.pathname.toLowerCase();
    var urlPlaylistId = 0;
    if (path.includes('/playlist/')) {
      const regex = /\/playlist\/([^\/?#]*)/;
      const match = path.match(regex);

      if (match && match[1]) {
        urlPlaylistId = parseInt(match[1]);
        if (!isNaN(urlPlaylistId) && urlPlaylistId > 0) {
          this.playlistId = urlPlaylistId;
          localStorage.setItem('playlistId', this.playlistId.toString());
        }
      }
    }

    if (urlPlaylistId === 0) {
      localStorage.setItem('playlistId', "0");
      this.playlistId = 0;
    }

    if (config.USE_YT_API) {
      this.currentPlayListYT = this.playlistsYT.filter(
        (playlistsYT) => playlistsYT.id === this.playlistId?.toString()
      )
    }
    else {
      this.currentPlayList = this.playlists.filter(
        (playlist) => playlist.id === this.playlistId
      )
    }

    //console.info('tailwindNavigationTemplate.ts (2)')
    if (urlPlaylistId > 0) {
      this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    }
    else if (path.includes('/first-steps')) {
      this.breadcrumbParam = 'Erste Schritte';
      localStorage.setItem('metaTitle', this.breadcrumbParam);
      localStorage.setItem('categoryLink', 'first-steps')
    }
  }

  // Lifecycle callback when the component is removed from the DOM.
  async disconnectedCallback(): Promise<void> {
    super.disconnectedCallback()
    window.removeEventListener('popstate', this.handlePopState.bind(this))
    document.removeEventListener('scroll', this.fixForDropdownMenuOnScroll)
    window.removeEventListener('resize', this.resizeListener)
  }

  // Lifecycle callback when the component is updated.
  updated(changedProperties: { has: (arg0: string) => any, get: (arg0: string) => any }) {
    if (changedProperties.has('showMobileMenu')) {
      // const showMobileMenuValue = changedProperties.get('showMobileMenu');
      // if (typeof showMobileMenuValue === 'undefined') return;
      this.showMobileMenu
        ? (this.showBreadCrumbs = false)
        : (this.showBreadCrumbs = true)
    }
  }

  // TODO: check if comeback from not playlist the breadcrumbs not show
  handlePopState(event: PopStateEvent) {
    //console.info('tailwindNavigationTemplate.ts (3) - handlePopState()')
    let parameter: any[] = []
    const isPlaylist = localStorage.getItem('isPlayList')
    if (event && event?.target && isPlaylist === 'true') {
      parameter = window.location?.pathname?.split('/')

      const id = parameter[parameter.length - 1]

      localStorage.setItem('playlistId', id)

      this.currentPlayList = this.playlists.filter(
        (playlist) => playlist.id === +id
      )
      setTimeout(() => {
        this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
      }, 350)
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    } else {
      parameter = window.location?.pathname?.split('/')
      const currentName: Navigation[] = this.nav.filter(
        (nav) => nav.link === parameter[1]
      )
      this.breadcrumbParam = currentName[0]?.name || ''
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    }
  }

  detectBreakpoint() {
    const width = window.innerWidth

    let newBreakpoint: Breakpoint

    if (width <= breakpoints.mobile) {
      newBreakpoint = 'mobile'
    } else if (width <= breakpoints.tablet) {
      newBreakpoint = 'tablet'
    } else if (width <= breakpoints.desktop) {
      newBreakpoint = 'desktop'
    } else if (width <= breakpoints.largeDesktop) {
      newBreakpoint = 'largeDesktop'
    } else if (width <= breakpoints.extraLargeDesktop) {
      newBreakpoint = 'extraLargeDesktop'
    } else {
      newBreakpoint = 'xxlDesktop'
    }

    if (newBreakpoint !== this.currentBreakpoint) {
      this.currentBreakpoint = newBreakpoint
      this.requestUpdate()
    }
  }

  private handleBreadCrumbs = (chip: string): void => {
    if (chip === 'hide') {
      this.showBreadCrumbs = false
    } else {
      this.showBreadCrumbs = true
    }
  }

  private preventLinkDefault = (e: Event) => e.preventDefault()

  private getVideosFromPlaylist = async (playlistId: string | number) => {

    // Wurde als Playlist-Id keine Zahl übergeben, wird angenommen, dass es sich um eine YT-Playlist-ID handelt.
    // Anhand dieser YT-Playlist-ID wird versucht, die entsprechende Playlist-ID zu ermitteln.
    if (config.USE_YT_API && typeof (playlistId) === 'string' && isNaN(Number(playlistId))) {
      const mappedPlaylistId = reverseMapping[playlistId]
      if (!isNaN(Number(mappedPlaylistId))) {
        playlistId = mappedPlaylistId
      }
    }

    if (typeof (playlistId) === 'string' && isNaN(Number(playlistId))) {
      if (config.USE_YT_API) {
        // TODO: Playlist direkt mit YT Playlist ID aufrufen
      }
      // alert(`Video-Playlist ${playlistId} nicht gefunden!`);
      //return;
    }

    this.closeDropdown()
    localStorage.setItem('categoryLink', '')

    if (config.USE_YT_API) {
      const mappedPlaylistId = forwardMapping[playlistId]

      if (mappedPlaylistId !== undefined) {
        this.currentPlayListYT = this.playlistsYT.filter(
          (playlistsYT) => playlistsYT.id === mappedPlaylistId.id
        )
      }
    }
    else {
      this.currentPlayList = this.playlists.filter(
        (playlist) => playlist.id === +playlistId
      )
    }

    //console.info('tailwindNavigationTemplate.ts (4) - getVideosFromPlaylist()')
    if (config.USE_YT_API) {
      setTimeout(() => {
        this.breadcrumbParam = this.currentPlayListYT[0]?.snippet.title || ''
        this.breadcrumbParam = this.breadcrumbParam.replace('RA-MICRO', '')
        this.breadcrumbParam = this.breadcrumbParam.trim()
        localStorage.setItem('metaTitle', this.breadcrumbParam)
      }, 350)
    }
    else {
      setTimeout(() => {
        this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
        localStorage.setItem('metaTitle', this.breadcrumbParam)
      }, 350)
    }



    const url = `/playlist/${playlistId}`
    this.useUrlParams && window.history.pushState({}, '', url)
    router.render(url)

    if (typeof (playlistId) === 'string' && isNaN(Number(playlistId))) {
      return;
    }

    this.nav = this.nav.map((navItem) => {
      if (navItem.playlistID === playlistId) {
        return { ...navItem, isActive: true }
      } else {
        return { ...navItem, isActive: false }
      }
    })
    this.requestUpdate('nav', this.nav)
  }

  private getVideosFromCategory = async (link: string) => {
    this.closeDropdown()
    localStorage.setItem('isPlayList', 'false')
    localStorage.setItem('playlistId', '0')
    localStorage.setItem('categoryLink', link)
    const currentName: Navigation[] = this.nav.filter(
      (nav) => nav.link === link
    )
    setTimeout(() => {
      this.breadcrumbParam = currentName[0]?.name || ''
    }, 350)
    this.useUrlParams && window.history.pushState({}, '', urlForName(link))
    router.render(urlForName(link))
    this.nav = this.nav.map((navItem) => {
      if (navItem.link === link) {
        return { ...navItem, isActive: true }
      } else {
        return { ...navItem, isActive: false }
      }
    })
    this.requestUpdate('nav', this.nav)
  }

  private handleDropdownMouseEnter = () => {
    if (this.dropdownBodyRef?.value) {
      this.dropdownBodyRef?.value.setAttribute('data-dropdown', 'open')
      this.dropdownBodyRef?.value.classList.toggle('hidden')
    } else {
      console.error('dropdownBodyRef is undefined')
    }
  }

  private closeDropdown = () => {
    if (this.dropdownBodyRef?.value) {
      this.dropdownBodyRef?.value.setAttribute('data-dropdown', 'closed')
      this.dropdownBodyRef?.value.classList.add('hidden')
      this.showMobileMenu = false
    } else {
      console.error('dropdownBodyRef is undefined')
    }
  }

  fixForDropdownMenuOnScroll = (): void => {
    if (
      this.dropdownBodyRef?.value?.dataset?.dropdown &&
      this.dropdownBodyRef?.value?.dataset?.dropdown === 'open'
    ) {
      this.closeDropdown()
      this.showMobileMenu = false
    }
  }

  /**
   * Erzeugt das Dropdown-Menü für den Punkt "Kanzleisoftware" in der Navigation.
   * @param navItem 
   * @param dropdownItems 
   * @returns 
   */
  protected renderDropdown(navItem: any, dropdownItems: any): TemplateResult {
    return html`
      <div class="">
        <div class="inline-block relative w-full">
          <button
            class="py-2 inline-flex items-center mr-1 link-color outline-none focus:outline-none ${navItem.isActive
        ? 'text-black'
        : ''}"
            ${ref(this.dropdownRef)}
            @click="${this.preventLinkDefault}"
            @mouseenter="${this.handleDropdownMouseEnter}"
          >
            ${navItem.name}
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </button>
          <ul
            class="dropdown-menu p-2 absolute hidden text-gray-700 z-30 grid gap-2 bg-gray-100 grid-cols-4 grid-rows-5 transform -translate-x-1/3 rounded-md sm:min-w-640 lg:min-w-800 shadow-sm transition-all duration-300 ease-in-out"
            ${ref(this.dropdownBodyRef)}
            @mouseleave="${this.closeDropdown}"
          >
            ${alphabeticSort(dropdownItems).map((item: Playlist) => {

          const playlistId = item?.id.toString()

          // vOffice - playlistId: 28, YT Id: PLpxrXPWuxxsvvOx9UK_dOipyltQoOMjqN
          // Online-Services - playlistId: 32, YT Id: PLpxrXPWuxxsv2OvmfLzHy7tQOCqRVe3CC
          // DictaNet - playlistId: 33, YT Id: PLpxrXPWuxxssL2gJxT-gMH2sgolHuxQEz
          // Apps - playlistId: 34, YT Id: PLpxrXPWuxxsviddhY39R9MxULa-8RAzja
          // Allgemeine Themen - playlistId: 35, YT Id: PLpxrXPWuxxst1xZgBZmvwnokjiq5UCRxf
          // Technik - playlistId: 36, YT Id: PLpxrXPWuxxsuBPLjN_k8wbVkkRX-8bhno
          // Erste Schritte - playlistId: --, YT Id: PLpxrXPWuxxssZPMorP-dJkWlbt-aZsk5k

          if (config.USE_YT_API) {
            if (playlistId === 'PLpxrXPWuxxsvvOx9UK_dOipyltQoOMjqN' ||
              playlistId === 'PLpxrXPWuxxsv2OvmfLzHy7tQOCqRVe3CC' ||
              playlistId === 'PLpxrXPWuxxssL2gJxT-gMH2sgolHuxQEz' ||
              playlistId === 'PLpxrXPWuxxsviddhY39R9MxULa-8RAzja' ||
              playlistId === 'PLpxrXPWuxxst1xZgBZmvwnokjiq5UCRxf' ||
              playlistId === 'PLpxrXPWuxxsuBPLjN_k8wbVkkRX-8bhno' ||
              playlistId === 'PLpxrXPWuxxssZPMorP-dJkWlbt-aZsk5k' ||
              playlistId === 'PLpxrXPWuxxss0JlWSNTcuxlEoE55Y6EeY' ||
              playlistId === 'PLpxrXPWuxxsuNj8fC201s85liJZdNklzJ' ||
              playlistId === 'PLpxrXPWuxxsvMqBeIriTgRa3ppt86AXYg') return html``
          }
          else {
            if (playlistId === '28' ||
              playlistId === '32' ||
              playlistId === '33' ||
              playlistId === '34' ||
              playlistId === '35' ||
              playlistId === '36') return html``
          }

          if (config.USE_YT_API && item.contentDetails.itemCount === 0) return html``

          return html`
                ${(config.USE_YT_API || item.privacy.label === 'Public' && item.videosLength > 0)
              ? html`
                      <li class="w-full flex items-center justify-center">
                        <button
                          @click="${() => {
                  this.getVideosFromPlaylist(playlistId)
                }}"
                          class="tailwindNavigationTemplate playlistId-${playlistId} bg-gray-100 hover:bg-gray-200 hover:rounded-md w-full py-2 px-4 block whitespace-no-wrap link-color whitespace-no-wrap cursor-pointer outline-none focus:outline-none text-left text-sm"
                          title="${config.USE_YT_API ? item.snippet.description : item.description}"
                        >
                          ${config.USE_YT_API ? item.snippet.title.replace('RA-MICRO', '') : item.displayName}
                        </button>
                      </li>
                    `
              : ''}
              `
        })
      }
          </ul>
        </div>
      </div>
    `
  }

  protected render(): TemplateResult {
    return html`
      <header
        class="bg-white w-full flex flex-wrap flex-col items-center py-2 mb-2 sticky top-0 z-10 container px-4 lg:px-0 justify-end lg:justify-center lg:mx-auto"
      >
        <nav class="w-full flex items-center bg-white justify-between">
        ${ /* Logo */ ''}
          <div class="mr-4 lg:flex-1">
            <a href="/mediathek/">
              <img
                @click="${() => (this.breadcrumbParam = '')}"
                src="${this.logo}"
                alt="${this.title}"
                class="pl-1 logo-fix min-w-80 w-80 lg:min-w-32 lg:w-32 cursor-pointer"
              />
            </a>
          </div>

          ${/* Navigation - Start */ ''}
          <ul class="hidden lg:flex items-center justify-center text-base text-gray-700 flex-auto">
            ${this.nav.map(
      (navItem) => html`
                <li class="list-none">
                  ${navItem.link === 'playlists' && this[navItem.link]
          ? this.renderDropdown(
            navItem,
            Object.values(this[navItem?.link])
          )
          : html`
                        <button
                          @click="${() => {
              if (config.USE_YT_API) {
                navItem.playlistID === 0
                  ? this.getVideosFromCategory(navItem.link)
                  : this.getVideosFromPlaylist(navItem.playlistID)
              }
              else {
                navItem.playlistID === 0 || isNaN(parseInt(navItem.playlistID.toString()))
                  ? this.getVideosFromCategory(navItem.link)
                  : this.getVideosFromPlaylist(navItem.playlistID)
              }
            }}"
                          @mouseenter="${this.closeDropdown}"
                          class="link-color py-2 px-4 block whitespace-no-wrap cursor-pointer outline-none focus:outline-none ${navItem.isActive
              ? 'text-black'
              : ''}"
                        >
                          ${navItem.name}
                        </button>
                      `}
                </li>
              `
    )}
          </ul>
          ${/* Navigation - Ende */ ''}

          ${/* Suche in Navigation - Start */ ''}
          <div class="flex-auto ${this.currentBreakpoint}">
          ${/* Suchfeld für größte Displays neben der Navigation */ ''}
          ${/*this.search && this.currentBreakpoint === 'xxlDesktop'
        ? html`<video-search
                  .handleBreadCrumbs="${this.handleBreadCrumbs}"
                ></video-search>`
  : html``*/''}
          </div>
          ${/* Suche in Navigation - Ende */ ''}

          <slot></slot>
          <!-- Mobile menu -->
          <mobile-menu-tailwind
            .showMobileMenu="${this.showMobileMenu}"
            .nav="${this.nav}"
            .getVideosFromCategory="${this.getVideosFromCategory}"
            .getVideosFromPlaylist="${this.getVideosFromPlaylist}"
            .playLists="${this.playlists}"
          ></mobile-menu-tailwind>
        </nav>

        ${/* Suchfeld für kleinere Displays unterhalb der Navigation */ ''}
        ${this.search &&
        this.currentBreakpoint === 'mobile' ||
        this.currentBreakpoint === 'tablet' ||
        this.currentBreakpoint === 'desktop'
        ? html`<div class="w-full my-2">
              <video-search
                .handleBreadCrumbs="${this.handleBreadCrumbs}"
              ></video-search>
            </div>`
        : html``}


        ${/* Breadcrumbs und - für große Displays - Suchfeld */ ''}
        ${this.currentBreakpoint === 'largeDesktop' || this.currentBreakpoint === 'extraLargeDesktop' || this.currentBreakpoint === 'xxlDesktop'
        ?
        html`
        <div class="w-full my-2 flex flex-row items-center">
              <breadcrumbs-tailwind
                class="w-1/2"
                .showBreadCrumbs="${this.showBreadCrumbs}"
                .breadcrumbParam="${this.breadcrumbParam}"
              ></breadcrumbs-tailwind>
              ${this.search
            ? html`
                <video-search
                      class="w-1/2"
                        .handleBreadCrumbs="${this.handleBreadCrumbs}"
                      ></video-search>
                      `
            : html`
                 `
          }
              </div>
              `
        : html`
        <breadcrumbs-tailwind
          class="w-full"
          .showBreadCrumbs="${this.showBreadCrumbs}"
          .breadcrumbParam="${this.breadcrumbParam}"
        ></breadcrumbs-tailwind>
        `}   
      </header>

      ${/* Testausgabe/DQ */ ''}
      ${/*this.currentBreakpoint === 'mobile'
        ? html`<div class="h-16">mobile</div>`
        : this.currentBreakpoint === 'tablet'
          ? html`<div class="h-16">tablet</div>`
          : this.currentBreakpoint === 'desktop'
            ? html`<div class="h-16">desktop</div>`
            : this.currentBreakpoint === 'largeDesktop'
              ? html`<div class="h-16">largeDesktop</div>`
              : this.currentBreakpoint === 'extraLargeDesktop'
                ? html`<div class="h-16">extraLargeDesktop</div>`
              : html`<div class="h-16">xxlDesktop</div>`*/ ''}
    `
  }
}

// Define the custom element "header-tailwind".
// Hier auskommentiert und oben durch @customElement('header-tailwind') ersetzt/DQ/16.05.2024
//customElements.define('header-tailwind', HeaderTailwind)
