import playlistIdMapping from './playlist-id-mapping.json';

type PlaylistIdMapping = {
    [key: string]: {
        id: string;
        name: string;
    };
};

const forwardMapping: PlaylistIdMapping = playlistIdMapping;
const reverseMapping: { [id: string]: string } = {};

for (const key in forwardMapping) {
    const { id } = forwardMapping[key];
    reverseMapping[id] = key;
}

export { forwardMapping, reverseMapping };
