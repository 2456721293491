export function parseQueryString(queryString: string): { [key: string]: string } {

    const query: { [key: string]: string } = {};
    const pairs = (queryString[0] === '?' ? queryString.slice(1) : queryString).split('&');
    
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}
