import { appContext } from '@/context/appContext'
import AppContext from '@/interfaces/AppContext'
import { urlForName } from '@/router'
import { consume } from '@lit-labs/context'
import { LitElement, html, css, CSSResultArray, unsafeCSS } from 'lit'
import { property, state } from 'lit/decorators.js'
import fondationcss from 'styles/foundation.css?inline'
export class BreadCrumbsFoundation extends LitElement {
  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext;

  [key: string]: any
  static styles: CSSResultArray = [
    css`
      .ram-breadcrumbs {
        margin-top: 1.5rem;
        margin-bottom: 0;
        font-size: 14px;
        padding: 0.5rem;
        font-family: 'Gudea', sans-serif;
        color: #777;
      }
      .nav-menu-item {
        display: block;
        padding: 0.25rem 1rem;
        cursor: pointer;
      }
      .home-icon {
        width: 0.75rem;
        height: 0.75rem;
      }
      .ram-breadcrumb-param {
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        margin-top: 2px;
      }
      .ram-breadcrumb-param button {
        margin-left: 0.5rem;
      }
      .ram-breadcrumbs ol {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      .ram-breadcrumbs ol li {
        display: flex;
        align-items: center;
      }
      .no-padding-x {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    `,
    unsafeCSS(fondationcss),
  ]

  @state() breadcrumb?: string = localStorage.getItem('metaTitle') || ''

  @property({ type: Boolean }) useUrlParams = false
  @property({ type: Boolean }) showBreadCrumbs = false
  @property({ type: String }) breadcrumbParam = ''

  handleHomeClick() {
    this.breadcrumbParam = ''
    this.breadcrumb = ''
    localStorage.setItem('metaTitle', '')
  }
  render() {
    return html`
    <div class="ram-breadcrumbs">
          <ol>
            <li>
              <a href="${urlForName('home')}" @click="${this.handleHomeClick}">
                <svg
                  class="home-icon"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"
                  />
                </svg>
                Home
              </a>
            </li>
            <li>
              <div class="ram-breadcrumb-param">
                ${
                  (this.breadcrumbParam || this.breadcrumb) &&
                  this.breadcrumbParam !== 'Home'
                    ? html`<svg
                        class="home-icon"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>`
                    : ''
                }
                <button
                  class="disabled"
                  disabled
                >
                ${
                  this.breadcrumbParam
                    ? this.breadcrumbParam !== 'Home'
                      ? this.breadcrumbParam
                      : ''
                    : this.breadcrumb !== 'Home'
                    ? this.breadcrumb
                    : ''
                }
                </button>
              </div>
            </li>
          </ol>
        </div>
      </div>`
  }
}

customElements.define('breadcrumbs-foundation', BreadCrumbsFoundation)
