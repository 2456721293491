import config from '@/config'

export const alphabeticSort = (arr: any[]): any[] => {
  arr.sort((a, b) => {
    const nameA = config.USE_YT_API ?
      a.snippet.title.toUpperCase() :
      a.displayName.toUpperCase()
    const nameB = config.USE_YT_API ?
      b.snippet.title.toUpperCase() :
      b.displayName.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return arr
}
