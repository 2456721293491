import {
  LitElement,
  html,
  css,
  TemplateResult,
  unsafeCSS,
  CSSResultArray,
} from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import 'components/scrollToTop'
import 'pages/page-home'
import 'components/footer'
import 'components/themeSwitcher'

import indexCss from '@/index.css?inline'
import tailwindcssStyles from 'styles/tailwind.css?inline'
import foundationCssStyles from 'styles/foundation.css?inline'

import 'templates/navigation/tailwindNavigationTemplate'
import 'templates/navigation/foundationNavigationTemplate'
import 'templates/tailwindTemplate'
import 'templates/foundationTemplate'

import { attachRouter } from '@/router/index.ts'
import { provide } from '@lit-labs/context'
import { appContext } from '@/context/appContext'
import AppContext from 'interfaces/AppContext'
import anime from 'animejs'
import { themeContext } from '@/context/themeContext'
import ThemeContext from './interfaces/ThemeContext'

@customElement('ram-mediathek')
export class RAMMediathek extends LitElement {
  static styles: CSSResultArray = [
    css`
      :host {
        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Regular.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Bold.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Italic.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Italic.woff') format('woff');
          font-weight: 400;
          font-style: italic;
        }
        font-family: 'Gudea', sans-serif;
      }
    `,
  ]

  // get the properties from the parent element
  @property({ type: Boolean, attribute: 'show-badge-new-video' })
  showBadgeNewVideo = false
  @property({ type: Boolean, attribute: 'use-url-params' }) useUrlParams = false
  @property({ type: Boolean, attribute: 'use-light-dom' }) useLightDom = false
  @property({ type: Boolean, attribute: 'use-embedded-style' })
  useEmbeddedStyle = false
  @property({ type: Boolean, attribute: 'navigation' })
  hasNavigation = false
  @property({ type: Boolean, attribute: 'search', reflect: true }) search =
    false
  @property({ type: Boolean, attribute: 'use-embedded-js' }) useEmbeddedJs =
    false
  @property({ type: Boolean, attribute: 'show-more-button' }) showMoreButton =
    false
  @property({ type: String, attribute: 'title' })
  title = ''
  @property({ type: String, attribute: 'logo' })
  logo = ''

  @property({ type: String, attribute: 'framework' })
  framework = 'tailwind'

  @property({ attribute: false })
  @query('main')
  main!: HTMLElement

  @query('header')
  header!: HTMLElement

  @query('.preloader')
  preloader!: HTMLElement

  @query('.st0')
  svg!: HTMLElement

  @state() theme = localStorage.getItem('theme') || 'light'

  // flag for first load
  private isFirstLoad = true

  // provide context
  @provide({ context: appContext })
  @property({ attribute: false })
  context!: AppContext

  @provide({ context: themeContext })
  themeContext: ThemeContext = {
    theme: this.theme,
    setTheme: () => {
      this.theme = this.theme === 'dark' ? 'light' : 'dark'
    },
  }

  // add constructor with super() to avoid error
  constructor() {
    super()
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  // add flag "useLightDom" to createRenderRoot() for using light dom instead of shadow dom
  createRenderRoot() {
    return this.useLightDom ? this : super.createRenderRoot()
  }

  async connectedCallback() {
    super.connectedCallback()

    if (this.getAttribute('title')) {
      this.title = this.getAttribute('title') as string
    }
    if (this.hasAttribute('use-light-dom')) {
      this.useLightDom = true
      localStorage.setItem('use-light-dom', 'true')
    }
    if (this.hasAttribute('show-badge-new-video')) {
      this.showBadgeNewVideo = true
      localStorage.setItem('show-badge-new-video', 'true')
    }
    if (this.hasAttribute('navigation')) {
      this.hasNavigation = true
      localStorage.setItem('hasNavigation', 'true')
    }

    if (this.hasAttribute('search')) {
      this.search = true
      localStorage.setItem('search', 'true')
    }

    if (this.hasAttribute('use-url-params')) {
      this.useUrlParams = true
      localStorage.setItem('use-url-params', 'true')
    }

    if (this.framework === 'foundation') {
    }

    // update context with properties on connectedCallback
    this.context = {
      showBadgeNewVideo: this.showBadgeNewVideo,
      useLightDom: this.useLightDom,
      useEmbeddedStyle: this.useEmbeddedStyle,
      useEmbeddedJs: this.useEmbeddedJs,
      hasNavigation: this.hasNavigation,
      search: this.search,
      showMoreButton: this.showMoreButton,
      title: this.title,
      logo: this.logo,
      framework: this.framework,
      useUrlParams: this.useUrlParams,
    }
  }

  getStyles() {
    return this.framework === 'tailwind'
      ? unsafeCSS(tailwindcssStyles)
      : unsafeCSS(foundationCssStyles)
  }

  // load $outleet in main element
  async firstUpdated() {
    attachRouter(this.main)

    const indexStyles = document.createElement('style')
    indexStyles.innerHTML = indexCss
    document.head.append(indexStyles)
  }

  async animateOut() {
    // this.preloader.style.display = 'flex'
    const duration = this.isFirstLoad ? 1500 : 500

    if (this.main) {
      await anime({
        targets: [this.main],
        opacity: [1, 0],
        duration: duration,
        easing: 'easeInOutQuad',
      }).finished
    }
    this.isFirstLoad = false
  }

  async animateIn() {
    const duration = this.isFirstLoad ? 1500 : 500
    if (this.main) {
      await anime({
        targets: [this.main],
        opacity: [0, 1],
        duration: duration,
        easing: 'easeInOutQuad',
      })
      this.preloader.style.display = 'none'
    }
  }

  // clear local storage on disconnect
  handleBeforeUnload(_event: BeforeUnloadEvent) {
    //console.info("ram-mediathek.ts - handleBeforeUnload()");
    localStorage.setItem('metaTitle', '')
  }

  // render the component html
  protected render(): TemplateResult {
    return html`
      <style>

        .preloader {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .path {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
          animation: dash 3s linear infinite alternate;
        }

        @keyframes dash {
          to {
            stroke-dashoffset: 0;
          }
          from {
            stroke-dashoffset: 500;
          }
        }
        ${unsafeCSS(this.getStyles())}
      </style>
      <div class="${this.theme}">
        <div
          class="${this.framework === 'tailwind'
        ? `container mx-auto flex flex-col min-h-screen`
        : `grid-container`}"
        >
          <header>
            ${this.hasNavigation
        ? this.framework === 'tailwind'
          ? html`<header-tailwind
                    .title="${this.title}"
                    .logo="${this.logo}"
                    .search="${this.search}"
                  >
                  </header-tailwind>`
          : html`<header-foundation
                    .title="${this.title}"
                    .logo="${this.logo}"
                    .search="${this.search}"
                  >
                  </header-foundation>`
        : ''}
          </header>
          <div class="preloader">
            <svg
              version="1.1"
              id="Ebene_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              style="enable-background:new 0 0 100 100; width: 200px"
              xml:space="preserve"
            >
              <style type="text/css"></style>
              <path
                class="path"
                fill="#ffffff"
                stroke="#ed0000"
                stroke-width="1"
                d="M34.1,59.5v6.3l16,7.8l24.4-12.2v-6.3L50.1,67.3L34.1,59.5z M93,42.6L50.1,21L7.2,42.6l18.5,9.1v24.4l6.3,3.1
	V54.8l18.5,9.1L93,42.6z M50.1,39.4l-18.5,9.1l-12.2-6.3l30.7-15.3l30.7,15.3L50.1,57.6l-12.2-6.3l18.5-9.1L50.1,39.4z"
              />
            </svg>
          </div>
          ${this.framework === 'tailwind'
        ? html`<main
                role="main"
                class="flex-grow"
                id="outleet"
              ></main>`
        : html`<main
                role="main"
                class="container"
                id="outleet"
              ></main>`}
          <footer class="${this.framework === 'foundation' ? 'container' : ''}">
            <mediathek-footer .framework="${this.framework}"></mediathek-footer>
          </footer>
          <scroll-to-top .framework="${this.framework}"></scroll-to-top>
        </div>
      </div>
    `
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }
}
