import {
  CSSResultArray,
  LitElement,
  TemplateResult,
  html,
  unsafeCSS,
} from 'lit'
import navigation from 'utils/nav-menu.json'
import Navigation from 'interfaces/Navigation'
import { property } from 'lit/decorators.js'
import Categories from 'interfaces/Categories'
import foundationCssStyles from 'styles/foundation.css?inline'
import Playlist from '@/interfaces/PlayLists'
import { alphabeticSort } from '@/helpers/alphabeticSort'
import { appContext } from '@/context/appContext'
import AppContext from 'interfaces/AppContext'
import { consume } from '@lit-labs/context'
import { router, urlForName } from '@/router'
import { apiService } from '@/services/api.service'
import errorMessages from 'utils/error-messages-de.json'

import '@/components/breadcrumbsFoundation'

// Define the Breakpoint type
type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'largeDesktop'

// Define breakpoints in pixels
const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1200,
}

// Define the custom element "header-foundation" representing the header with navigation, breadcrumbs, and responsive layout.
export class HeaderFoundation extends LitElement {
  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext;

  // Define the properties of the custom element using @property decorator.
  [key: string]: any
  static styles: CSSResultArray = [unsafeCSS(foundationCssStyles)]

  @property({ type: Array, attribute: false }) nav: Navigation[] =
    Object.values(navigation)
  @property({ type: Array, attribute: false }) categories: Categories[] = []
  @property({ type: String, attribute: 'title', reflect: true })
  title = ''
  @property({ type: Array, attribute: false }) playlists: Playlist[] = []
  @property({ type: Boolean, attribute: 'search' })
  @property({ type: Boolean, attribute: false })
  useUrlParams = false
  @property({ type: Boolean, attribute: false }) showBreadCrumbs = false
  @property({ type: String, attribute: 'logo', reflect: true }) logo = ''
  @property({ type: String, attribute: false, reflect: true }) breadcrumbParam =
    ''
  @property({ type: String, attribute: false }) currentBreakpoint: Breakpoint =
    'largeDesktop'
  resizeListener = () => this.detectBreakpoint()
  @property({ type: Number, attribute: 'playlistId', reflect: true })
  playlistId?: number = +localStorage.getItem('playlistId')! || 0

  async connectedCallback(): Promise<void> {
    super.connectedCallback()
    if (this.shadowRoot) {
      if (
        !this.shadowRoot.querySelector(
          'script[src="https://cdnjs.cloudflare.com/ajax/libs/foundation/6.4.3/js/foundation.min.js"]'
        )
      ) {
        const script = document.createElement('script')
        script.src =
          'https://cdnjs.cloudflare.com/ajax/libs/foundation/6.4.3/js/foundation.min.js'
        script.onload = () => {
          //  @ts-ignore
          $(this.shadowRoot).foundation()
        }

        this.shadowRoot.appendChild(script)
      }
    }
    document.addEventListener('scroll', this.fixForDropdownMenuOnScroll)
    window.addEventListener('popstate', this.handlePopState.bind(this))
    this.detectBreakpoint()
    window.addEventListener('resize', this.resizeListener)

    this.useUrlParams = this.context.useUrlParams
    if (this.context?.breadcrumbs) this.breadcrumbs = this.context?.breadcrumbs
    const playLists = await apiService.getPlaylists(errorMessages.playlists)
    this.playlists = playLists.data
    this.currentPlayList = this.playlists.filter(
      (playlist) => playlist.id === this.playlistId
    )
    this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
  }
  async disconnectedCallback(): Promise<void> {
    super.disconnectedCallback()
    window.removeEventListener('popstate', this.handlePopState.bind(this))
    document.removeEventListener('scroll', this.fixForDropdownMenuOnScroll)
    window.removeEventListener('resize', this.resizeListener)
  }

  // Lifecycle callback when the component is updated.
  updated(changedProperties: { has: (arg0: string) => any }) {
    if (changedProperties.has('showMobileMenu')) {
      this.showMobileMenu
        ? (this.showBreadCrumbs = false)
        : (this.showBreadCrumbs = true)
    }
  }

  // TODO: check if comeback from not playlist the breadcrumbs not show
  handlePopState(event: PopStateEvent) {
    let parameter: any[] = []
    const isPlaylist = localStorage.getItem('isPlayList')
    if (event && event?.target && isPlaylist === 'true') {
      parameter = window.location?.pathname?.split('/')

      const id = parameter[parameter.length - 1]

      localStorage.setItem('playlistId', id)

      this.currentPlayList = this.playlists.filter(
        (playlist) => playlist.id === +id
      )
      setTimeout(() => {
        this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
      }, 350)
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    } else {
      parameter = window.location?.pathname?.split('/')
      const currentName: Navigation[] = this.nav.filter(
        (nav) => nav.link === parameter[1]
      )
      this.breadcrumbParam = currentName[0]?.name || ''
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    }
  }

  private preventLinkDefault = (e: Event) => e.preventDefault()

  private getVideosFromPlaylist = async (playlistId: string | number) => {
    localStorage.setItem('categoryLink', '')

    this.currentPlayList = this.playlists.filter(
      (playlist) => playlist.id === +playlistId
    )
    setTimeout(() => {
      this.breadcrumbParam = this.currentPlayList[0]?.displayName || ''
      localStorage.setItem('metaTitle', this.breadcrumbParam)
    }, 350)
    const url = `/mediathek/playlist/${playlistId}`
    this.useUrlParams && window.history.pushState({}, '', url)
    router.render(url)
    this.nav = this.nav.map((navItem) => {
      if (navItem.link === 'playlists') {
        return { ...navItem, isActive: true }
      } else {
        return { ...navItem, isActive: false }
      }
    })
    this.requestUpdate('nav', this.nav)
  }

  private getVideosFromCategory = async (link: string) => {
    localStorage.setItem('isPlayList', 'false')
    localStorage.setItem('playlistId', '0')
    localStorage.setItem('categoryLink', link)
    const currentName: Navigation[] = this.nav.filter(
      (nav) => nav.link === link
    )

    setTimeout(() => {
      this.breadcrumbParam = currentName[0]?.name || ''
    }, 350)
    this.useUrlParams && window.history.pushState({}, '', urlForName(link))
    router.render(urlForName(link))
    this.nav = this.nav.map((navItem) => {
      if (navItem.link === link) {
        return { ...navItem, isActive: true }
      } else {
        return { ...navItem, isActive: false }
      }
    })
    this.requestUpdate('nav', this.nav)
  }

  detectBreakpoint() {
    const width = window.innerWidth

    let newBreakpoint: Breakpoint

    if (width <= breakpoints.mobile) {
      newBreakpoint = 'mobile'
    } else if (width <= breakpoints.tablet) {
      newBreakpoint = 'tablet'
    } else if (width <= breakpoints.desktop) {
      newBreakpoint = 'desktop'
    } else {
      newBreakpoint = 'largeDesktop'
    }

    if (newBreakpoint !== this.currentBreakpoint) {
      this.currentBreakpoint = newBreakpoint
      this.requestUpdate()
    }
  }

  /**
   * Renders a dropdown menu item.
   * @param navItem - The navigation item to render the dropdown for.
   * @param dropdownItems - The dropdown items to display in the submenu.
   * @param firstElementHandler - Optional event handler for the first element in the dropdown.
   * @returns The template result with the rendered HTML.
   */
  protected renderDropdown(
    navItem: any,
    dropdownItems: any,
    firstElementHandler: any = null
  ): TemplateResult {
    // ... (logic to render the dropdown menu item)
    // Returns a TemplateResult with the rendered HTML.
    return html`
      <style>
        .link-color {
          color: #777;
          font-weight: bold;
        }
        .link-color:hover {
          color: #333;
        }
        .link-color::after {
          border-color: #777 transparent transparent !important;
        }
        .link-color:hover::after {
          border-color: #333 transparent transparent !important;
        }
        .active {
          color: #333;
        }
        .top-bar-right {
          display: flex;
          align-items: center;
        }
      </style>
      <li
        class="has-submenu is-dropdown-submenu-parent opens-right"
        role="menuitem"
        aria-haspopup="true"
        aria-label="Alle Kategorien"
        data-is-click="false"
      >
        <span class="link-color ${navItem.isActive && 'active'}">
          <span
            @click="${() => {
              this.preventLinkDefault
            }}"
            style="white-space: nowrap"
          >
            ${navItem.link === 'categories' ? 'Alle Kategorien' : navItem.name}
          </span>
        </span>
        <ul
          class="submenu menu vertical is-dropdown-submenu first-sub"
          data-submenu
          role="menu"
        >
          ${firstElementHandler
            ? html` <li @click="${firstElementHandler}">
                <span
                  class="py-2 px-4 block whitespace-no-wrap cursor-pointer ${navItem.isActive
                    ? 'active'
                    : ''}"
                >
                  <span style="white-space: nowrap">Alle Kategorien</span>
                </span>
              </li>`
            : ''}
          ${alphabeticSort(dropdownItems).map((item: Playlist) => {
            return html`
              ${item.privacy.label === 'Public' && item.videosLength > 0
                ? html`<li
                    @click="${() =>
                      this.getVideosFromPlaylist(item?.id.toString())}"
                    role="menuitem"
                    class="is-submenu-item is-dropdown-submenu-item"
                  >
                    <span class="link-color">
                      <span
                        style="white-space: nowrap"
                        class="nav-menu-item"
                        title="${item.description}"
                        >${item.displayName}
                      </span>
                    </span>
                  </li> `
                : ''}
            `
          })}
        </ul>
      </li>
    `
  }

  /**
   * Renders the content of the custom element.
   * @returns The template result with the rendered HTML.
   */
  protected render(): TemplateResult {
    // ... (logic to render the header content)
    // Returns a TemplateResult with the rendered HTML.
    // TODO: add mobile menu
    return html`
      <style>
        .is-dropdown-submenu.js-dropdown-active {
          display: block;
        }
        .logo {
          width: 150px;
        }
        .top-bar {
          display: flex;
          align-items: center;
        }

        .nav-menu-item {
          display: block;
          padding: 0.25rem 1rem;
          cursor: pointer;
        }

        .no-padding-x {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      </style>
      <div class="grid-container no-padding-x">
        <div
          class="top-bar grid-x grid-margin-y  align-center"
          style="background-color: #f6f6f6"
        >
          <div
            class="top-bar-left"
            style="background-color: #f6f6f6"
          >
            <ul
              class="dropdown menu"
              data-dropdown-menu
              style="display: flex; align-items: center; background-color: #f6f6f6"
            >
              <li class="menu-text">
                <img
                  @click="${() => (this.breadcrumbParam = '')}"
                  src="${this.logo}"
                  alt="${this.title}"
                  class="logo"
                />
              </li>
              ${this.nav.map(
                (navItem) => html`
                  ${navItem.link === 'playlists'
                    ? this.renderDropdown(
                        navItem,
                        Object.values(this[navItem?.link])
                      )
                    : html`
                        <li>
                          <span
                            class="nav-menu-item"
                            @click="${() => {
                              navItem.playlistID === 0
                                ? this.getVideosFromCategory(navItem.link)
                                : this.getVideosFromPlaylist(navItem.playlistID)
                            }}"
                          >
                            <span
                              class="link-color ${navItem.isActive
                                ? 'active'
                                : ''}"
                            >
                              <span style="white-space: nowrap"
                                >${navItem.name}</span
                              >
                            </span>
                          </span>
                        </li>
                      `}
                `
              )}
            </ul>
            <slot></slot>
          </div>
          <div class="top-bar-right"></div>
        </div>
      </div>
      <breadcrumbs-foundation
        .showBreadCrumbs="${this.showBreadCrumbs}"
        .breadcrumbParam="${this.breadcrumbParam}"
      ></breadcrumbs-foundation>
    `
  }
}

// An array containing CSSResult objects representing the styles for the custom element.
HeaderFoundation.styles = [unsafeCSS(foundationCssStyles)]

// Define the custom element "header-foundation".
customElements.define('header-foundation', HeaderFoundation)
